<template>
  <div class="apply">
    <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="apply-form">
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="form.phone" maxlength="11"
          @input="
            form.phone = form.phone.replace(
              /^(0+)|[^\d]+/g,
              ''
            )
          "
        ></el-input>
      </el-form-item>
      <el-form-item label="注册邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="申请类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择申请类型" @change="changType($event)">
          <el-option label="apple海外账号" value="apple"></el-option>
          <el-option label="chatgpt账号" value="chatgpt"></el-option>
          <el-option label="科学上网" value="wall"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="apply('form')">{{ submitMsg }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from '@/api/user'
import { apply } from '@/api/order'
import { setToken } from '@/utils/auth'
import wx from 'weixin-js-sdk'
// import Cookies from 'js-cookie'

const querystring = require('querystring')

export default {
  data () {
    return {
      submitMsg: '提交',
      form: {
        phone: '',
        email: '',
        type: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /^((1\d{10}))$/, message: '请输入正确的联系电话', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入注册邮箱', trigger: 'blur' },
          { pattern: /^([0-9A-Za-z\-_]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: '请输入正确的注册邮箱', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择申请类型', trigger: 'change' }
        ]
      }
    }
  },
  async created () {
    await this.loginByWeixin()
  },
  methods: {
    // 切换申请类型
    changType (event) {
      if (event === 'apple') {
        this.submitMsg = '价格：29.9元'
      } else if (event === 'chatgpt') {
        this.submitMsg = '价格：29.9元'
      } else if (event === 'wall') {
        this.submitMsg = '价格：29.9元'
      }
    },
    // 微信登录
    async loginByWeixin () {
      const url = window.location.href
      const para = url.split('?')[1]
      if (!para || para.indexOf('code') === -1) {
        window.location.href = process.env.VUE_APP_BASE_API + '/v1/user/wechat/authorize'
      }
      let code = ''
      if (para) {
        const arg = querystring.parse(para)
        code = arg.code
      }
      const { data } = await login({ type: 'WX-H5', code: code })
      setToken(data.token)
    },
    // 提交申请
    apply (formName) {
      const _that = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data } = await apply(this.form)
          const appid = data.appid || ''
          _that.wexinPay(appid, data.jspay)
        } else {
          return false
        }
      })
    },
    // 微信支付
    wexinPay (appid, jspay) {
      const _that = this
      const { timestamp, nonceStr, signType, paySign } = jspay
      const packages = jspay.package
      console.log('config ----', {
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appid, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: paySign, // 必填，签名，见附录1
        jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appid, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: paySign, // 必填，签名，见附录1
        jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
      wx.ready(function () {
        wx.chooseWXPay({
          appId: appid,
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          // 支付成功
          success: function (res) {
            console.log(res)
            _that.$message.success('支付成功')
            _that.$router.push('/success')
          },
          // 支付取消
          cancel: function (res) {
            console.log(res)
            _that.$message.warning('支付取消')
          },
          // 支付失败
          fail: function (err) {
            console.log(err)
            _that.$message.error('支付失败')
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .apply {
    width: 100%;
    height: 100%;
    position: relative;
    .apply-form {
      width: 90%;
      height: 70%;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      .el-select {
        width: 100%;
      }
      .el-button {
        min-width: 50%;
      }
    }
  }
</style>
