<template>
  <div class="success">
    <div class="success-msg">
      <i class="el-icon-success"></i>
      支付成功
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.success {
  width: 100%;
  height: 100%;
  .success-msg {
    color: #409eff;
    padding-top: 33%;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }
}
</style>
